import st from "./Main.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

import swiper1 from "./images/swiper/swiper1.png";
import swiper2 from "./images/swiper/swiper2.png";
import swiper3 from "./images/swiper/swiper3.png";
import swiper4 from "./images/swiper/swiper4.png";
import swiper5 from "./images/swiper/swiper5.png";
// import swiper6 from "./images/swiper/swiper6.png";
// import swiper7 from "./images/swiper/swiper7.png";
// import swiper8 from "./images/swiper/swiper8.png";

import arrowRight from "./images/arrowright.png";
import share from "./images/share.png";
import upload from "./images/upload.png";
import reduce from "./images/reduce.png";
import deleteIcon from "./images/delete.png";
import info from "../Top/images/info.png";
import mobile from "./images/mobile.png";
import pc from "./images/pc.png";
import star from "./images/star.svg";
import halfStar from "./images/star-half.png";
import more from "./images/more.png";
import flag from "./images/flag.png";
import country from "./images/country.png";
import down from "./images/down.png";
import starGray from "./images/star-gray.png";
import user1 from "./images/users/user1.png";
import user2 from "./images/users/user2.png";
import user3 from "./images/users/user3.png";
import game1 from "./images/games/game1.png";
import game2 from "./images/games/game2.png";
import game3 from "./images/games/game3.png";
import game4 from "./images/games/game4.png";
import game5 from "./images/games/game5.png";
import game6 from "./images/games/game6.png";

const Main = (props) => {
    const { downLoadApp } = props;

    const clickHandler = () => {
        downLoadApp && downLoadApp();
    };

    const swiperList = [swiper1, swiper2, swiper3, swiper4,swiper5];



    const rateList = [
        { num: 5, width: "90%" },
        { num: 4, width: "20%" },
        { num: 3, width: "10%" },
        { num: 2, width: "0%" },
        { num: 1, width: "5%" },
    ];

    const footerList = [
        {
            title: "Google Play",
            children: ["Play Pass", "Play Points", "Gift cards", "Redeem", "Refund policy"],
        },
        {
            title: "Kids & family",
            children: ["Parent Guide", "Family sharing"],
        },
    ];

    const evaluateList = [
        // {
        //     avatar: user1,
        //     userName: "Lisa G",
        //     time: "November 15, 2023",
        //     content: "Update..plan on spending bank if you want to play. Wins are not enough to sustain playing for a decent amount of time. I agree with the other reviews stating that the winnings become less and less. The more coins you purchase, the more quickly you lose them. After purchasing the piggy bank, the price skyrocketed for any additional purchase. The more dedicated you are to playing, the higher the prices go. There are plenty of other slot apps available that have a much higher win/lose ratio.",
        //     userfulDesc: "2,008 people found this review helpful",
        // },
        {
            avatar: user2,
            userName: "Karen S",
            time: "October 15, 2023",
            content:
                "Great choice of popular games that are also in the casinos live, and lots of fun to play! Watching ads alone can sustain play, but can be agonizingly slow at times. Be prepared to make purchases or have lots of patience. Sometimes, you have to watch a 30 second to a minute long ad in between a few hands of play, and that can go on for several hands. On some games, you can't complete the bonus requirements unless you buy money to play. STILL, my favorite of all the casino apps to play!",
            userfulDesc: "3,394 people found this review helpful",
        },
        {
            avatar: user3,
            userName: "Donita C",
            time: "September 14, 2022",
            content: "I really enjoy this game. It has a variety of different slots to play. I can earn extra coins as I play. Not many ads to watch. The amount of coins when buying is lower than other games, but due to the quality of this game, they are worth it in my opinion. I have downloaded and then deleted other apps after playing them.",
            userfulDesc: "1,110 people found this review helpful",
        },
    ];

    const gameList1 = [
        {
            img: game1,
            title: "Lightning Link Casino Slots",
            desc: "Product Madness",
            star: "4.3",
        },
        {
            img: game2,
            title: "Quick Hit Casino Slot Games",
            desc: "SciPlay",
            star: "4.7",
        },
        {
            img: game3,
            title: "Lucky Time Slots Casino Games",
            desc: "DGN Games",
            star: "4.8",
        },
        {
            img: game4,
            title: "Cash Tornado™ Slots - Casino",
            desc: "Zeroo Gravity Games",
            star: "4.6",
        },
        {
            img: game5,
            title: "Gold Fish Casino Slot Games",
            desc: "SciPlay",
            star: "4.8",
        },
    
    ];


    return (
        <div className={st.container}>
            <div className={st.content}>
                <div className={st.contentLeft}>
                    <div className={st.swiperContainer}>
                        <Swiper
                            slidesPerView="auto"
                            className={st.swiperContent}
                            navigation={{ nextEl: ".navigation-next", prevEl: ".navigation-prev" }}
                            spaceBetween={12}
                            modules={[Navigation]}>
                            {swiperList.map((v, key) => {
                                return (
                                    <SwiperSlide key={key} style={{ width: "auto" }}>
                                        <img src={v} alt="" className={st.swiperImg} onClick={clickHandler} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div className={st.contentGameInfo}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                            About this game
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentGameInfoText}>
                        Experience the thrill of cards and the excitement of high-stakes gambling in 91bet! Join a vibrant community of card enthusiasts in this immersive and social card game. Play Teen Patti in various modes, engage in thrilling tournaments, and rise to become the ultimate 91bet. With its authentic gameplay, social features, and competitive spirit, 91bet offers a captivating card-playing experience that will keep you coming back for more.
                            <br />
                            <br />
                            1.Authentic Teen Patti Experience: Immerse yourself in the authentic and traditional Teen Patti card game, featuring realistic rules and engaging gameplay.
                            <br />
                            2.Diverse Game Modes: Explore a variety of game modes, from classic Teen Patti to innovative variations, providing a dynamic and entertaining gaming experience.
                            <br />
                            3.Social Card Gaming: Connect with friends, join clubs, and engage in multiplayer matches, fostering a social and interactive gaming environment.
                        </div>
                    </div>

                    <div className={st.contentSafe}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Data safety
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentSafeText}>
                        Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use, region, and age. The developer provided this information and may update it over time.
                        </div>
                        <div className={st.contentSafeTable}>
                            <div className={st.contentSafeTableItem}>
                                <img src={share} alt="" />
                                <div>
                                This app may share these data types with third parties
                                    <br />
                                    Location, Personal info and 6 others
                                </div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={upload} alt="" />
                                <div>
                                This app may collect these data types
                                    <br />
                                    Location, Personal info and 6 others
                                </div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={reduce} alt="" />
                                <div>Data is encrypted in transit</div>
                            </div>
                            <div className={st.contentSafeTableItem}>
                                <img src={deleteIcon} alt="" />
                                <div>You can request that data be deleted</div>
                            </div>
                            <div className={st.contentSafeTableDetail} onClick={clickHandler}>
                            See details
                            </div>
                        </div>
                    </div>
                    <div className={st.contentRate}>
                        <div className={st.contentRateTitle}>
                            <div className={st.infoTitle} onClick={clickHandler}>
                            Ratings and reviews
                                <img src={arrowRight} alt="" />
                            </div>
                            <div className={st.contentRateTitleR} onClick={clickHandler}>
                            Ratings and reviews are verified
                                <img src={info} alt="" />
                            </div>
                        </div>
                        <div className={st.contentRateDevice}>
                            <div className={st.contentRateDeviceItem} onClick={clickHandler}>
                                <img src={mobile} alt="" />
                                Phone
                            </div>
                            <div className={st.contentRateDeviceItemPc} onClick={clickHandler}>
                                <img src={pc} alt="" />
                                Tablet
                            </div>
                        </div>
                        <div className={st.contentRateDetail}>
                            <div className={st.contentRateDetailL}>
                                <div className={st.contentRateDetailLNum}>4.9</div>
                                <div className={st.contentRateDetailLStar}>
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={star} alt="" />
                                    <img src={halfStar} alt="" />
                                </div>
                                <div>422K reviews</div>
                            </div>
                            <div className={st.contentRateDetailR}>
                                {rateList.map((v, key) => {
                                    return (
                                        <div key={key} className={st.contentRateDetailRItem}>
                                            <div className={st.contentRateDetailRItemNum}>{v.num}</div>
                                            <div className={st.contentRateDetailRItemProgress}>
                                                <div className={st.contentRateDetailRItemProgressInner} style={{ width: v.width }}></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={st.contentRateEvaluate}>
                            {evaluateList.map((v, key) => {
                                return (
                                    <div key={key} className={st.contentRateEvaluateItem} onClick={clickHandler}>
                                        <div className={st.contentRateEvaluateItemTitle}>
                                            <img src={v.avatar} alt="" className={st.contentRateEvaluateItemTitleAvatar} />
                                            <div className={st.contentRateEvaluateItemTitleName}>{v.userName}</div>
                                            <img src={more} alt="" className={st.contentRateEvaluateItemTitleMore} />
                                        </div>
                                        <div className={st.contentRateEvaluateItemRate}>
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <img src={star} alt="" />
                                            <div>{v.time}</div>
                                        </div>
                                        <div className={st.contentRateEvaluateItemContent}>{v.content}</div>
                                        <div className={st.contentRateEvaluateItemUserful}>{v.userfulDesc}</div>
                                        <div className={st.contentRateEvaluateItemIsUserful}>
                                            <div className={st.contentRateEvaluateItemIsUserfulText}>Did you find this helpful?</div>
                                            <div className={st.contentRateEvaluateItemIsUserfulBtn}>Yes</div>
                                            <div className={st.contentRateEvaluateItemIsUserfulBtn}>No</div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className={st.contentRateEvaluateMore}>See all reviews</div>
                        </div>
                    </div>
                    <div className={st.contentNew}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        What's new
                        </div>
                        <div className={st.contentNewContent}>
                            Get our NEW games in our latest update!<br />
                            For the best experience and most FUN we’ve squashed bugs and optimized your game.<br />
                        </div>
                    </div>
                </div>
                <div className={st.contentRight}>
                    <div className={st.infoTitle} onClick={clickHandler}>
                    App support
                        <img src={down} alt="" />
                    </div>
                    <div className={st.contentRightOther}>
                        <div className={st.infoTitle} onClick={clickHandler}>
                        Similar games
                            <img src={arrowRight} alt="" />
                        </div>
                        <div className={st.contentRightOtherGameList}>
                            {gameList1.map((v, key) => {
                                return (
                                    <div key={key} className={st.contentRightGameItem} onClick={clickHandler}>
                                        <img src={v.img} alt="" className={st.contentRightGameItemImg} />
                                        <div className={st.contentRightGameItemImfo}>
                                            <div className={st.contentRightGameItemImfoTitle}>{v.title}</div>
                                            <div className={st.contentRightGameItemImfoDesc}>{v.desc}</div>
                                            <div className={st.contentRightGameItemImfoStar}>
                                                {v.star}
                                                <img src={starGray} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                </div>
            </div>
            <div className={st.report} onClick={clickHandler}>
                <img src={flag} alt="" />
                Flag as inappropriate
            </div>
            <div className={st.footer}>
                <div className={st.divider}></div>
                <div className={st.footerInfo}>
                    {footerList.map((v, key) => {
                        return (
                            <div key={key} className={st.footerInfoItem}>
                                <div className={st.footerInfoItemTitle} onClick={clickHandler}>
                                    {v.title}
                                </div>
                                {v.children.map((v1, key1) => {
                                    return (
                                        <div key={key1} className={st.footerInfoItemText} onClick={clickHandler}>
                                            {v1}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={st.desc} onClick={clickHandler}>
                <div>Terms of Service</div>
                <div>Privacidade</div>
                <div>Sobre o Google Play</div>
                <div>Desenvolvedores</div>
                <div>Google Store</div>
                <img src={country} alt="" />
                <div>United States (English)</div>
            </div>
            <div style={{ width: "100%", height: "36px" }}></div>
        </div>
    );
};

export default Main;
