import React from "react";
import Header from "components/Header/Header";
import Top from "components/Top/Top";
import Main from "components/Main/Main";
import TabBar from "components/TabBar/TabBar";

const Home = () => {
  const getCookieValue = (cookieName: string) => {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const downLoadApp = () => {
    const fbcValue = getCookieValue("_fbc") || "";
    const fbpValue = getCookieValue("_fbp") || "";
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get("fbclid");
    const utm_ad = urlParams.get("utm_ad");
    const utm_adset = urlParams.get("utm_adset");
    const web = urlParams.get("web");
    const invitecode = urlParams.get("invitecode");
    const webDecode = window.atob(web);
    let src = `https://apk.tbox.live/91bet.apk`;
    if (web) {
      src = `${webDecode}`;
    }
    if (web && invitecode) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_3&deep_link_value=${invitecode}&af_ss_ui=true`;
    }
    if (fbclid) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_3&pid=Social_facebook&af_ad=${utm_ad}&af_adset=${utm_adset}&af_sub1=${fbcValue}&af_sub2=${fbpValue}&af_ss_ui=true`;
    }
    if (fbclid && invitecode) {
      src = `${webDecode}?af_js_web=true&af_ss_ver=2_7_3&pid=Social_facebook&af_ad=${utm_ad}&af_adset=${utm_adset}&af_sub1=${fbcValue}&af_sub2=${fbpValue}&deep_link_value=${invitecode}&af_ss_ui=true`;
    }
    const link = document.createElement("a");
    // console.log(src);
    link.href = src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header downLoadApp={downLoadApp} />
      <Top downLoadApp={downLoadApp} />
      <Main downLoadApp={downLoadApp} />
      <TabBar downLoadApp={downLoadApp} />
    </div>
  );
};

export default Home;
