import st from "./Top.module.scss";

import gameImg from "./images/game.png";
import icon18 from "./images/18+.png";
import info from "./images/info.png";
import star from "./images/star.png";
import share from "./images/share.svg";
import add from "./images/add.svg";
import device from "./images/devices.png";

const Top = (props) => {
    const {downLoadApp} = props;

    const clickHandler = () => {
        downLoadApp && downLoadApp();
    };

    return (
        <div className={st.container}>
            <div className={st.contentVideo}>
                <div className={st.contentVideoContent}>
                    <video id={st.contentVideo} autoPlay muted loop>
                        <source src={require("./images/accjy.mp4")} type="video/mp4" />
                    </video>
                    <div className={st.contentVideoMask}></div>
                </div>
            </div>
            <div className={st.content}>
                <div className={st.contentEmpty}></div>
                <div className={st.contentInfo}>
                    <div className={st.infoTitle}>
                        <img src={gameImg} alt="" className={st.infoTitleImg} />
                        <div className={st.infoTitleContent} onClick={clickHandler}>
                            <div className={st.infoName}>91bet</div>
                            <div className={st.infoDesc}>Product Madness</div>
                            <div className={st.infoDesc2}>In-app purchases</div>
                        </div>
                    </div>
                    <div className={st.infoDownLoad} onClick={clickHandler}>
                        <img src={gameImg} alt="" className={st.infoDownLoadItemgameImgImg} />
                        <div className={st.infoDownLoadItem}>
                            <div className={st.infoDownLoadItemName}>
                                4.9
                                <img src={star} alt="" />
                            </div>
                            <div className={st.infoDownLoadItemDesc}>497K reviews</div>
                        </div>
                        <div className={st.infoDownLoadDivider}></div>
                        <div className={st.infoDownLoadItem}>
                            <div className={st.infoDownLoadItemName}>10M+</div>
                            <div className={st.infoDownLoadItemDesc}>Downloads</div>
                        </div>
                        <div className={st.infoDownLoadDivider}></div>
                        <div className={st.infoDownLoadItem}>
                            <div className={st.infoDownLoadItemName}>
                                <img src={icon18} alt="" className={st.infoDownLoadItemName12} />
                            </div>
                            <div className={st.infoDownLoadItemDesc}>
                                18 +
                                <img src={info} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className={st.infoHandler}>
                        <div className={st.infoHandlerDownload} onClick={clickHandler}>
                        Install
                        </div>
                        <div className={st.infoHandlerShare}>
                            <div className={st.infoHandlerShareItem} onClick={clickHandler}>
                                <img src={share} alt="" />
                                <div className={st.infoHandlerShareItemName}>Share</div>
                            </div>
                            <div className={st.infoHandlerShareItem} onClick={clickHandler}>
                                <img src={add} alt="" />
                                <div className={st.infoHandlerShareItemName}>Add to wishlist</div>
                            </div>
                        </div>
                    </div>
                    <div className={st.infoDevice}>
                        <img src={device} alt="" />
                        <div>This app is available for your device</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Top;
